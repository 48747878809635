import axios from 'axios'
import router from '@/router'
import { MessageBox } from 'element-ui'

const request = axios.create({
    baseURL: 'https://page.tracking5.fun/api/',
    // baseURL: 'http://localhost:8081',
    // baseURL: 'http://10.0.0.7:8081/',
    timeout: 120000
})



// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    // config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}//获取登录时存放的user对象信息
    config.headers['token'] = user.token  // 设置请求头
    return config  // 确保返回config
}, error => {
    return Promise.reject(error)
})

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return response
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        //如果token过期，则弹出登录
        if (res.code === "605") {
            // this.$router.push("/login");
            // localStorage.removeItem("user");
            // localStorage.removeItem("menus")

            //用户登录超时，重新登陆
            MessageBox.alert('登录信息超时,请重新登录！', '登录超时', {
                confirmButtonText: '确定',
                callback: action => {
                    //跳转登录页   callback点击确定按钮后的回调函数
                    router.replace({
                        path: "/login",
                    })
                }
            })
        }
        return res
    },
    error => {
        if (error && error.response) {
            error.data = {}
            switch (error.response.code) {
                case 400:
                    error.data.msg = '错误请求'
                    ElMessage.error(error.data.msg)
                    break
                case 401:
                    error.data.msg = '未授权，请重新登录'
                    ElMessage.error(error.data.msg)
                    break
                case 403:
                    error.data.msg = '拒绝访问'
                    ElMessage.error(error.data.msg)
                    break
                case 404:
                    error.data.msg = '请求错误,未找到该资源'
                    ElMessage.error(error.data.msg)
                    break
                case 405:
                    error.data.msg = '请求方法未允许'
                    ElMessage.error(error.data.msg)
                    break
                case 408:
                    error.data.msg = '请求超时'
                    ElMessage.error(error.data.msg)
                    break
                case 500:
                    error.data.msg = '服务器端出错'
                    ElMessage.error(error.data.msg)
                    break
                case 501:
                    error.data.msg = '网络未实现'
                    ElMessage.error(error.data.msg)
                    break
                case 502:
                    error.data.msg = '网络错误'
                    ElMessage.error(error.data.msg)
                    break
                case 503:
                    error.data.msg = '服务不可用'
                    ElMessage.error(error.data.msg)
                    break
                case 504:
                    error.data.msg = '网络超时'
                    ElMessage.error(error.data.msg)
                    break
                case 505:
                    error.data.msg = 'http版本不支持该请求'
                    ElMessage.error(error.data.msg)
                    break
                default:
                    error.data.msg = `连接错误${error.response.status}`
                    ElMessage.error(error.data.msg)
            }
        } else {
            error.data.msg = "连接到服务器失败"
            ElMessage.error(error.data.msg)
        }

        return Promise.reject(error)
    }
)

export default request
